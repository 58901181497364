import { FC, useState } from "react"
import { Button } from "react-admin";
import { AddExperimentDialog } from "./AddExperimetsDialog";

export const AddExperimentButton: FC<Props> = () => {
  const [
    addExperimentDialogPayload,
    setAddExperimentDialogPayload
  ] = useState<true | undefined>();

  return (
    <>
      <AddExperimentDialog
        payload={addExperimentDialogPayload}
        hideDialog={() => setAddExperimentDialogPayload(undefined)} />
      <Button
        label="add"
        onClick={() => setAddExperimentDialogPayload(true)} />
    </>
  );
}

type Props = {}