import React, { useMemo } from 'react';
import {
  FilterButton,
  TextInput,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { AddExperimentButton } from './widget/AddExperimentButton';
import { RowClickEvent } from '../../common/Table';

export const resource = "admin/generic-views/ab_test_experiment_view";

const tableColumns = [
  { field: "id", width: "120px", title: "id", show: false },
  { field: "experiment_name", width: "200px", title: "experiment name", show: true },
  { field: "trigger_moments", width: "200px", title: "trigger moments", show: true },
  { field: "targeting_rules", width: "200px", title: "targeting rules", show: true },
  { field: "variants", width: "200px", title: "variants", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" alwaysOn />,
  <TextInput label="experiment_name" source="experiment_name.like" alwaysOn />,
];

export const ABTestExperimentCollection: React.FC<Props> = (props) => {
  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  const rowClickHandler = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    console.log(dataItem);

    // const { user_id } = dataItem;

    // if (user_id === undefined) {
    //   throw Error('no user_id in row click event');
    // }

  }

  return (
    <Resource
      resource={resource}
      filters={filters}
      disableUrlFilterListener={false}
      rowActionBuilder={rowActionBuilder}
      onRowClick={rowClickHandler}
      columnModel={columnModel}
      tableActions={
        <>
          <AddExperimentButton />
          <FilterButton {...props} />
        </>
      }
      rowPerPageOptions={[25, 50, 100]}
    />
  )
}

type Props = {
  initialFilters?: { [index: string]: string }
  onRowClick?: (userId: number) => void
}