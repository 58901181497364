import React, { useMemo, useState } from 'react';
import {
  DateInput,
  FilterButton,
  NumberInput,
  TextInput,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
import { RowClickEvent } from "../../common/Table";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { DollarInput } from '../../common/DollarInput';

export const resource = "admin/generic-views/collection_overview";

const tableColumns = [
  { field: "user_id", width: "120px", title: "user id", show: true },
  { field: "first_name", width: "170px", title: "last name", show: false },
  { field: "last_name", width: "170px", title: "last name", show: false },
  { field: "phone", width: "120px", title: "phone", show: true },
  { field: "email", width: "120px", title: "email", show: true },
  { field: "date_of_birth", width: "120px", title: "date of birth", show: true, format: "yyyy-MM-dd" },
  { field: "credit_line_id", width: "120px", title: "credit line id", show: true },
  { field: "user_created_at", width: "120px", title: "user_created_at", show: true, format: "yyyy-MM-dd" },
  { field: "last_4_ssn", width: "120px", title: "last 4 ssn", show: true },
  { field: "principal_balance", width: "120px", title: "principal balance", show: true, isCent: true },
  { field: "past_due_date_unpaid_amount", width: "120px", title: "past due date unpaid amount", show: true, isCents: true },
  { field: "current_dpd", width: "120px", title: "current dpd", show: true, format: "yyyy-MM-dd" },
  { field: "dpd_num", width: "120px", title: "dpd num", show: true },
  { field: "repayment_plan", width: "120px", title: "repayment plan", show: true },
  { field: "third_party_name", width: "170px", title: "third party name", show: true },
  { field: "number_of_promises_to_pay", width: "170px", title: "number of promises to pay", show: true },
  { field: "recent_equifax_fico", width: "170px", title: "recent equifax fico", show: true },
  { field: "recent_experian_fico", width: "180px", title: "recent experian fico", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="user id" source="user_id.eq" alwaysOn />,
  <TextInput label="phone" source="phone.like" />,
  <TextInput label="first_name" source="first_name.like" />,
  <TextInput label="last_name" source="last_name.like" />,
  <TextInput label="email" source="email.like" />,
  <TextInput label="credit_line_id" source="credit_line_id.like" />,
  <TextInput label="last_4_ssn" source="last_4_ssn.like" />,
  <TextInput label="repayment_plan" source="repayment_plan.like" />,
  <TextInput label="third_party_name" source="third_party_name.like" />,

  <DateInput label="date of birth gt" source="date_of_birth.gt" />,
  <DateInput label="date of birth lt" source="date_of_birth.lt" />,

  <DateInput label="user_created_at" source="user_created_at.gt" />,
  <DateInput label="user_created_at" source="user_created_at.lt" />,

  <DollarInput label="principal_balance gt (Dollar)" source="principal_balance.gt" />,
  <DollarInput label="principal_balance lt (Dollar)" source="principal_balance.lt" />,

  <DollarInput label="current_dpd gt (Dollar)" source="current_dpd.gt" />,
  <DollarInput label="current_dpd lt (Dollar)" source="current_dpd.lt" />,

  <NumberInput label="dpd_num gt" source="dpd_num.gt" />,
  <NumberInput label="dpd_num lt" source="dpd_num.lt" />,

  <NumberInput label="number_of_promises_to_pay gt" source="number_of_promises_to_pay.gt" />,
  <NumberInput label="number_of_promises_to_pay lt" source="number_of_promises_to_pay.lt" />,
];


export const DebtCollection: React.FC<Props> = (props) => {

  const {
    onSet,
    disableUrlFilterListener,
    initialFilters,
  } = props;

  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions
    record={record}
  />

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    if (onSet === null || onSet === undefined) return;

    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { user_id } = dataItem;

    if (user_id === undefined) {
      throw Error('no user_id in row click event');
    }

    if (user_id !== undefined) {
      onSet(user_id);
    }
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Resource
      resource={resource}
      filters={filters}
      initialFilters={initialFilters}
      disableUrlFilterListener={disableUrlFilterListener}
      rowActionBuilder={rowActionBuilder}
      onRowClick={handleRowClick}
      columnModel={columnModel}
      tableActions={
        <>
          <FilterButton {...props} />
        </>
      }
    />
  );
}

type Props = {
  onSet?: (id: string | null | undefined) => void,
  initialFilters?: { [index: string]: any }
  disableUrlFilterListener: boolean
}