import React, { FC, useCallback, useEffect, useState } from 'react';
import { Alert, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import { RefundType } from '../../../network/MembershipActions';

export type RefundPFMSubscriptionDialogPayload = {
  userId: number
} | null;

export const RefundPFMSubscriptionDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { membershipActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [refundTypeValue, setRefundTypeValue] = useState<RefundType>("SetupOnly");

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      setRefundTypeValue("SetupOnly");
    }

    setError(null);
  }, [payload]);

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId } = payload ?? {};

    if (userId === undefined) {
      notify("no userId provided");
      return;
    }

    const error = await membershipActions.refundPfmSubscription(userId, refundTypeValue);

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Done");
    }
  }

  const handleChangeRefundTypeValue = useCallback((e: SelectChangeEvent<RefundType>) => {
    setRefundTypeValue(e.target.value as RefundType);
  }, [refundTypeValue]);

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Refund subscription"
      actionTitle="Run"
      onSend={handleSendClick}>

      <FormControl fullWidth>
        <InputLabel id="account-id-select-label1">Refund type</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="refund-type-select-label1"
          id="demo-simple-select1"
          value={refundTypeValue}
          label="underwriting content"
          onChange={handleChangeRefundTypeValue}>
          <MenuItem key={"SetupOnly"} value={"SetupOnly"}>SetupOnly</MenuItem>
          <MenuItem key={"FullRefund"} value={"FullRefund"}>FullRefund</MenuItem>
          <MenuItem key={"NetRefund"} value={"NetRefund"}>NetRefund</MenuItem>
        </Select>
      </FormControl>

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: RefundPFMSubscriptionDialogPayload;
  hideDialog: () => void;
}