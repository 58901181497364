import { Box, Card, Typography } from "@mui/material"
import { FC } from "react"
import { BTLimits } from "../../../network/BTActions";
import { centsToDollars } from "../../../helpers";

export const LimitsCard: FC<Props> = ({ currentLimits }) => {

  return (
    <Box sx={{ maxWidth: "400px" }}>

      <Typography variant="body2" sx={{ display: 'block' }}>
        BASE LIMIT (will be sent) {centsToDollars(currentLimits.baseLimit)}
      </Typography>
      <Typography variant="body2" sx={{ display: 'block', color: "text.secondary" }}>
        APPROVED LIMIT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {centsToDollars(currentLimits.approvedLimit)}
      </Typography>
      <Typography variant="body2" sx={{ display: 'block', color: "text.secondary" }}>
        BOOSTER INCREASE &nbsp;&nbsp;&nbsp; {centsToDollars(currentLimits.boosterIncrease)}
      </Typography>
      <Typography variant="body2" sx={{ display: 'block', color: "text.secondary" }}>
        PREMIUM INCREASE &nbsp;&nbsp;&nbsp;&nbsp; {centsToDollars(currentLimits.premiumIncrease)}
      </Typography>
      <Typography variant="body2" sx={{ display: 'block', color: "text.secondary" }}>
        REWARDS INCREASE &nbsp;&nbsp;&nbsp; {centsToDollars(currentLimits.rewardsIncrease)}
      </Typography>

    </Box >
  )
}

type Props = {
  currentLimits: BTLimits
}