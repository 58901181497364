import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UpdateIcon from '@mui/icons-material/Update';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import { RefundType } from '../../../network/MembershipActions';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export const RowActions: React.FC<Props> = ({
  record,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const notify = useNotify();
  const refresh = useRefresh();
  const { membershipActions } = useInjection();

  const handleOpenButtonPopup = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCancelMembership = async (e: React.MouseEvent, immediately: boolean) => {
    e.preventDefault();

    const {
      user_id: userId,
    } = record;

    const error = await membershipActions.cancelSubscription(userId, immediately);

    if (error !== null) {
      notify(error);
    } else {
      notify("Done");
      refresh();
    }

    setAnchorEl(null);
  }

  const handleRefundPFMSubscription = async (
    e: React.MouseEvent,
    refundType: RefundType,
  ) => {
    e.preventDefault();

    const {
      user_id: userId,
    } = record;

    if (!window.confirm(`Are you shure? Refund '${refundType}' for user ${userId} ?`)) {
      setAnchorEl(null);
      return;
    }

    const error = await membershipActions.refundPfmSubscription(userId, refundType);

    if (error !== null) {
      notify(error);
    } else {
      notify("done");
      refresh();
    }

    setAnchorEl(null);
  }

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        size='small'
        color='inherit'
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleOpenButtonPopup}
        endIcon={<KeyboardArrowDownIcon />}>
        Actions
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>

        <MenuItem onClick={(e) => handleCancelMembership(e, false)} disableRipple>
          <UpdateIcon />
          Membership cancel [not immediately]
        </MenuItem>

        <MenuItem onClick={(e) => handleCancelMembership(e, true)} disableRipple>
          <UpdateIcon />
          Membership cancel [immediately]
        </MenuItem>

        <MenuItem onClick={(e) => handleRefundPFMSubscription(e, "SetupOnly")} disableRipple>
          <UpdateIcon />
          Refund PFM subscription "Setup Only"
        </MenuItem>

        <MenuItem onClick={(e) => handleRefundPFMSubscription(e, "FullRefund")} disableRipple>
          <UpdateIcon />
          Refund PFM subscription "Full Refund"
        </MenuItem>

        <MenuItem onClick={(e) => handleRefundPFMSubscription(e, "NetRefund")} disableRipple>
          <UpdateIcon />
          Refund PFM subscription "Net Refund"
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

type Props = {
  record: { [index: string]: any }
}