import React, { useMemo } from 'react';
import {
  FilterButton,
  TextInput,
} from "react-admin"
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { Box } from '@mui/material';

export const resource = "admin/generic-views/underwriting_log_view";

const tableColumns = [
  { field: "decision_id", width: "120px", title: "decision id", show: true },
  { field: "check_name", width: "300px", title: "check name", show: true },
  { field: "check_result", width: "300px", title: "check result", show: true },
  { field: "reason", width: "400px", title: "reason", show: true },
  { field: "is_considered_in_decision", width: "300px", title: "is_considered_in_decision", show: true },

  // { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="decision id" source="decision_id.eq" alwaysOn />,
  <TextInput label="check_name" source="check_name.eq" alwaysOn />,
  <TextInput label="check_result" source="check_result.eq" />,
  <TextInput label="reason" source="reason.eq" />,
  <TextInput label="is_considered_in_decision" source="is_considered_in_decision.eq" />,
];


export const UnderwritingLogView: React.FC<Props> = (props) => {
  // const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px"
      }}>
        <h4>
          {"Underwriting log"}
        </h4>
      </Box>

      <Resource
        resource={resource}
        filters={filters}
        disableUrlFilterListener={false}
        // rowActionBuilder={rowActionBuilder}
        columnModel={columnModel}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
        rowPerPageOptions={[10, 25, 50, 100]}
      />
    </Box >
  );
}

type Props = {
  initialFilters?: { [index: string]: string }
  onRowClick?: (userId: number) => void
}