import React, { useMemo, } from 'react';
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { DateInput, FilterButton, SelectInput, TextInput } from 'react-admin';
import { DollarInput } from '../../common/DollarInput';

export const resource = "admin/generic-views/income_info_log";

const tableColumns = [
  { field: "id", width: "100px", title: "id", show: true },
  { field: "created_at", width: "150px", title: "created at", show: true, format: "yyyy-MM-dd" },
  { field: "user_id", width: "100px", title: "id", show: false },
  { field: "uw_product", width: "200px", title: "uw product", show: true },
  { field: "estimated_limit", width: "200px", title: "estimated limit", show: true, isCents: true, },
  { field: "monthly_argyle_income", width: "200px", title: "mo argyle income", show: true, isCents: true },
  { field: "monthly_accounts_income", width: "200px", title: "mo accounts income", show: true, isCents: true, },
  { field: "monthly_full_income", width: "150px", title: "mo full income", show: true, isCents: true, },
  { field: "monthly_payments", width: "200px", title: "mo payments", show: true, isCents: true },
  { field: "minimum_required_income", width: "150px", title: "min required income", show: true, isCents: true, },
  { field: "verification_result", width: "400px", title: "verification result", show: true },

  // { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" alwaysOn />,
  <TextInput label="uw_product" source="uw_product.eq" alwaysOn />,

  <DateInput label="created_at gt" source="created_at.gt" />,
  <DateInput label="created_at lt" source="created_at.lt" />,

  <DollarInput label="current_balance gt (dollar)" source="current_balance.gt" />,
  <DollarInput label="current_balance lt (dollar)" source="current_balance.lt" />,

  // <SelectInput source="current_status" choices={[
  //   { id: 'onHold', name: 'onHold' },
  //   { id: 'Canceled', name: 'Canceled' },
  //   { id: 'Sent', name: 'Sent' },
  //   { id: 'Scheduled', name: 'Scheduled' },
  // ]} />,

  // <SelectInput source="card token is expired" choices={[
  //   { id: 'true', name: 'Expired' },
  //   { id: 'false', name: 'Not expired' },
  // ]} />,

  // <TextInput label="card_id" source="card_id.eq" />,
];

export const IncomeInfoLogCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
    rowPerPageOptions = [10, 25, 50],
  } = props;

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  const customValueFormat = {
    "verification_result": (results?: any[] | { argyle: any[], plaid: any }) => {
      if (results === undefined || results === null) {
        return ``;
      }

      if (Array.isArray(results)) {
        return results.map(
          (r) => `${r.title} \n ${r.verificationType} isCompleted: ${r.isCompleted}`
        ).join('\n\n');
      } else {
        const argyle = results.argyle.map(
          (r) => `${r.title} \n ${r.verificationType} isCompleted: ${r.isCompleted}`
        ).join('\n\n');

        const plaid = results.plaid.map(
          (r: any) => `${r.title} \n ${r.verificationType} isCompleted: ${r.isCompleted}`
        ).join('\n\n');

        return `PLAID:\n\n${plaid}\n\n\nARGYLE:\n\n${argyle}`;
      }

    },
  };

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px"
      }}>
        <h4>
          {`Income Info Log (user: ${initialFilters?.user_id?.eq})`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        columnModel={columnModel}
        rowPerPageOptions={rowPerPageOptions}
        filters={filters}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
        customValueFormat={customValueFormat}
        isCustomValueFormatPre={true}
      />
    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
  rowPerPageOptions?: number[]
  disableUrlFilterListener?: boolean
}