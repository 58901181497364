
import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type ITargetingRules = {
  "tag": string
  "contents": JSON
}

export type IVariant = {
  "value": string,
  "probability": number,
  "isDefaultValue": boolean
}

type AddABTestPayload = {
  experimentName: string,
  targetingRules: ITargetingRules[],
  triggerMoments: string[],
  variants: IVariant[]
}

type RemoveAbTestPayload = {
  experimentName: string
}

type UpdateAbVariantAssignmentPayload = {
  userId: number
  experimentName: string
  variant: string
}

type UpdateVariantsPayload = {
  experimentName: string
  variants: IVariant[]
}

export class ABTestActions {
  constructor(private adminRequest: AdminRequest) { };

  async addAbTest(payload: AddABTestPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/ab-test/add',
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request /admin/ab-test/add failed";
    }
  }

  async removeAbTest(payload: RemoveAbTestPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/ab-test/remove',
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request /admin/ab-test/remove failed";
    }
  }

  async getVariantsForExperiment(experimentName: string): Promise<PostError | string[]> {
    try {
      const abExperiments = await this.adminRequest.getView<{ variants: string[] }[]>(
        `/admin/generic-views/ab_test_experiment_view?experiment_name=eq.${experimentName}`,
      );

      if (abExperiments.length === 0) {
        return "No variants";
      } else {
        return abExperiments[0].variants;
      }
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request /admin/generic-views/ab_test_experiment_view?experiment_name=eq. failed";
    }
  }

  async updateAbTestAssignment(payload: UpdateAbVariantAssignmentPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/ab-test/assignment/update',
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request update ab-test assignment update failed";
    }
  }

  async updateVariants(payload: UpdateVariantsPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/ab-test/variants/update',
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request /admin/ab-test/variants/update failed";
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
