import React, { useMemo } from 'react';
import {
  FilterButton,
  TextInput,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';

export const resource = "admin/generic-views/ab_test_user_assignment_view";

const tableColumns = [
  { field: "id", width: "120px", title: "id", show: true },
  { field: "user_id", width: "120px", title: "user_id", show: true },
  { field: "experiment_name", width: "200px", title: "experiment name", show: true },
  { field: "variant", width: "200px", title: "variant", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" />,
  <TextInput label="user id" source="user_id.eq" alwaysOn />,
  <TextInput label="experiment name" source="experiment_name.eq" alwaysOn />,
];


export const ABTestUserAssignmentCollection: React.FC<Props> = (props) => {
  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (<>
    <Resource
      resource={resource}
      filters={filters}
      disableUrlFilterListener={false}
      rowActionBuilder={rowActionBuilder}
      columnModel={columnModel}
      tableActions={
        <>
          <FilterButton {...props} />
        </>
      }
      rowPerPageOptions={[25, 50, 100]}
    />
  </>
  )
}

type Props = {
  initialFilters?: { [index: string]: string }
  onRowClick?: (userId: number) => void
}