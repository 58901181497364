import { Box } from "@mui/material";
import { ABTestUserAssignmentCollection } from "../../collections/ab-test-user-assignment-collection/ABTestUserAssignmentCollection";
import { ABTestExperimentCollection } from "../../collections/ab-test-experiments-collection copy/ABTestExperimentCollection";

const ABTestsDashboard = () => {
  return (
    <section className='dashboard'>
      <h1>AB tests</h1>

      <Box sx={{ display: "flex", maxWidth: "100%" }}>
        <Box sx={{ marginRight: "20px" }}>
          <ABTestExperimentCollection />
          <ABTestUserAssignmentCollection />
        </Box>

      </Box>

    </section >
  );
}

export default ABTestsDashboard;
