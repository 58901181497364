import { Admin, Resource, combineDataProviders, DataProvider, ListGuesser, ShowGuesser } from 'react-admin'
import { CreditLineList } from '../components/CreditLineList'
import { CreditLineShow } from '../components/CreditLineShow'
import UserIcon from '@material-ui/icons/Group'
import authProvider from '../providers/authProvider'
import { dataProvider as creditLinesDataProvider } from '../providers/dataProvider'
import { Constants } from '../constants'
import { buildDataProvider } from '../providers/buildDataProvider'
import DebtDashboard from './dashboards/DebtDashboard'
import { AdminLayout } from '../layout/AdminLayout'
import { UnderwritingDashboard } from './dashboards/UnderwritingDashboard'
import { UnderwritingUserDashboard } from './dashboards/UnderwritingUserDashboard'
import ClientsDashboard from './dashboards/ClientsDashboard'
import ClientDashboard from './dashboards/ClientDashboard'
import BTPaymentsDashboard from './dashboards/BTPaymentsDashboard'
import SBPaymentsDashboard from './dashboards/SBPaymentsDashboard'
import { config } from '../config'
import { ExecutionDashboard } from './dashboards/ExecutionsDashboard'
import { UnderwritingMonitoringDashboard } from './dashboards/UnderwritingMonitoringDashboard'
import { sanitizeSpace } from '../helpers'
import { MembershipPaymentsDashboard } from './dashboards/MembershipPaymentsDashboard'
import SubscriptionsDashboard from './dashboards/SubscriptionsDashboard'
import ABTestsDashboard from './dashboards/ABTestsDashboard'

const dataProvider = combineDataProviders(
  (resource: string): DataProvider<string> => {
    switch (resource) {
      case Constants.CREDIT_LINES:
        return creditLinesDataProvider;
      default:
        return buildDataProvider(config.apiUrl);
    }
  }
);

const selectDashboard = (dashboard: string, param?: string): any => {
  switch (dashboard) {
    case "debt":
      return () => <DebtDashboard />;
    case "underwriting":
      return () => <UnderwritingDashboard />;
    case "underwriting-user":
      return () => <UnderwritingUserDashboard userId={sanitizeSpace(param ?? "")} />
    case 'clients':
      return () => <ClientsDashboard />;
    case 'client':
      return () => <ClientDashboard userId={sanitizeSpace(param ?? "")} />;
    case 'bt-payments':
      return () => <BTPaymentsDashboard />;
    case 'sb-payments':
      return () => <SBPaymentsDashboard />;
    case 'executions':
      return () => <ExecutionDashboard />;
    case 'underwriting-monitoring':
      return () => <UnderwritingMonitoringDashboard />;
    case 'membership-payments':
      return () => <MembershipPaymentsDashboard />;
    case 'subscriptions':
      return () => <SubscriptionsDashboard />;
    case 'ab-test':
      return () => <ABTestsDashboard />;
  }
}

const DashboardApp: React.FC<any> = (
  props: { params: { dashboard: string, param: string } }
) => {
  const { params: { dashboard: dashboardName, param } } = props;

  const dashboard = selectDashboard(dashboardName, param);

  return (
    <div>
      <Admin
        title="My Custom Admin"
        authProvider={authProvider}
        dataProvider={dataProvider}
        dashboard={dashboard}
        basename='/'
        layout={AdminLayout}>

        <Resource
          name={Constants.CREDIT_LINES}
          options={{ label: 'Credit lines' }}
          list={CreditLineList}
          icon={UserIcon}
          show={CreditLineShow}
        />

      </Admin>
    </div>
  )
}

export default DashboardApp
