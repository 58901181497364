
import React, { FC, useEffect, useState } from 'react';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useNotify, useRefresh } from 'react-admin';
import { PostError } from '../../../network/AdminRequest';
import { useInjection } from '../../../di/useInjection';
import { Alert, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

export type UpdateVariantAssignmentDialogPayload = {
  userId: string
  experimentName: string
  currentVariant: string
} | null;

export const UpdateVariantAssignmentDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { abTestActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [error, setError] = useState<PostError>(null);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [variants, setVariants] = useState<string[]>([]);
  const [variant, setVariant] = useState<string | null>(payload?.currentVariant ?? null);


  useEffect(() => {
    setVariants([]);
    setError(null);

    if (payload === null) return;

    const { experimentName } = payload;

    if (experimentName === undefined) {
      notify("No experimentName found");
      return;
    }

    setIsRequesting(true);
    abTestActions.getVariantsForExperiment(experimentName).then((result) => {
      setIsRequesting(false);
      if (typeof result == "string") {
        setError(result);
      } else {
        setVariants(result as string[]);
      }
    }).finally(() => setIsRequesting(false));

  }, [payload]);

  const handleChangeVariant = (e: React.ChangeEvent<HTMLInputElement>, variant: string) => {
    e.stopPropagation();
    e.preventDefault();
    setVariant(variant);
  }

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId, experimentName } = payload;

    if (variant === null) {
      setError("Variant no selected")
      return;
    }

    if (userId === undefined || experimentName === undefined) {
      notify("Error: no userId or experimentName")
      return;
    }

    const error = await abTestActions.updateAbTestAssignment({
      userId: parseInt(userId.toString()),
      experimentName,
      variant,
    });

    if (error === null) {
      hideDialog();
      refresh();
      notify("Updated");
    } else {
      setError(error);
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title={`Update variant for ${payload?.experimentName} user ${payload?.userId}`}
      onSend={handleSendClick}
      isSendDisabled={isRequesting || variant === null || variant === payload?.currentVariant}
      actionTitle="Update">


      <FormControl>
        <FormLabel id="radio-buttons-group-label">Variants</FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          defaultValue={payload?.currentVariant}
          onChange={handleChangeVariant}
          name="radio-buttons-group">
          {variants.map((variant) =>
            <FormControlLabel value={variant} control={<Radio />} label={variant} />
          )}
        </RadioGroup>
      </FormControl>

      {error !== null && <Alert color={"error"} severity="error">{error}</Alert>}
    </SimpleDialog>
  );
}

type Props = {
  payload: UpdateVariantAssignmentDialogPayload;
  hideDialog: () => void;
}