import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type Expenses = {
  loansAndCreditCards?: number,
  mortgage?: number,
  other?: number,
  rent?: number
}

export type UpdateExpansesPayload = {
  userId: number,
  expenses?: Expenses,
}

export class CustomerActions {
  constructor(private adminRequest: AdminRequest) { };


  async getExpanses(userId: number): Promise<PlacidResponse> {
    try {
      return await this.adminRequest.post(
        '/admin/customer/expenses',
        JSON.stringify({ userId }),
      );
    } catch (e) {
      return {
        success: false,
        error: {
          code: "Error",
          message: "Error fetching expenses",
        }
      }
    }
  }

  async updateExpanses(payload: UpdateExpansesPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/customer/expenses/set',
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request update expenses failed";
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
