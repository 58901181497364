import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Button as Btn,
  IconButton,
  Stack,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useInjection } from '../../../di/useInjection';
import { PostError } from '../../../network/AdminRequest';
import { Button, useNotify, useRefresh } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import { IVariant } from '../../../network/ABTestActions';

export type UpdateVariantsExperimentDialogPayload = {
  experimentName: string
  variants: string[]
} | null;

export const UpdateVariantsExperimentDialog: FC<UpdateVariantsExperimentDialogProps> = ({
  payload,
  hideDialog,
}) => {
  const { abTestActions } = useInjection();
  const notify = useNotify();
  const refresh = useRefresh();

  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const [variants, setVariants] = useState<IVariant[]>([]);

  useEffect(() => {
    console.log("use effect");
    setError(null);
    setIsRequesting(false);
    if (payload?.variants !== undefined) {
      const variants = (payload?.variants ?? []).map((v: string): IVariant => {
        const values = v.split(":");
        return {
          value: values[0].trim(),
          probability: parseInt(values[1].trim()),
          isDefaultValue: values.length === 3 ? true : false,
        }
      });
      setVariants(variants);
    }
  }, [payload]);

  const [error, setError] = useState<PostError>(null);

  const handleSendClick = async () => {
    if (isRequesting === undefined) return;

    setIsRequesting(true);
    setError(null);

    const experimentName = payload?.experimentName;

    if (experimentName === undefined) {
      notify("No experiment name");
      return;
    }

    const sumProbabilities = variants.map((v) => v.probability).reduce((a, sum) => a + sum, 0);

    if (sumProbabilities !== 100) {
      setIsRequesting(false);
      setError("Sum of probability should equal 100");
      return;
    }

    const error = await abTestActions.updateVariants({
      experimentName,
      variants,
    });

    setIsRequesting(false);
    if (error === null) {
      notify("Done");
      hideDialog();
      refresh();
    } else {
      setError(error);
    }
  }

  const handleUpdateVariantValue = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    const variantsSnapshot = [...variants];
    const value = e.target.value;
    const variant = variantsSnapshot[index];
    variantsSnapshot[index] = { ...variant, value }
    setVariants(variantsSnapshot);
  }

  const handleUpdateVariantProbability = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    try {
      const variantsSnapshot = [...variants];
      const probability = parseInt(e.target.value);
      const variant = variantsSnapshot[index];
      variantsSnapshot[index] = { ...variant, probability }
      setVariants(variantsSnapshot);
    } catch (_) { }
  }

  // const handleUpdateDefaultFlag = (e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean, index: number) => {
  //   e.stopPropagation();
  //   const variant = variants[index];
  //   const _variants = isSelect ? variants.map((v) => {
  //     v.isDefaultValue = false;
  //     return v;
  //   }) : variants;

  //   _variants[index] = { ...variant, isDefaultValue: isSelect }

  //   setVariants([..._variants]);
  // }

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Update variants"
      onSend={handleSendClick}
      isSendDisabled={isRequesting}
      cancelTitle='CLOSE'
      actionTitle={isRequesting ? "Saving..." : "Update"}>

      <Box style={{ "display": "flex" }}>
        <h6 style={{ marginTop: 8, marginRight: 10 }}>Variants</h6>
        {/* <Button
          size='small'
          label="add"
          onClick={() => setVariants([...variants, { value: '', isDefaultValue: false, probability: 0 }])} /> */}
      </Box>

      {variants.map((variant, index) => {
        return <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            id={`variant_value_${index}`}
            label="value"
            value={variant.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateVariantValue(e, index)}
            disabled
          />

          <TextField
            fullWidth
            id={`variant_probability_${index}`}
            label="probability"
            value={variant.probability}
            type="number"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateVariantProbability(e, index)}
            style={{ marginTop: 8 }}
          />

          {/* <FormControlLabel
            style={{ marginTop: 12 }}
            id={`variant_default_${index}`}
            control={
              <Checkbox
                checked={variant.isDefaultValue}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>, isSelected: boolean) => handleUpdateDefaultFlag(e, isSelected, index)
                }
              />}
            label="" /> */}

          {/* <IconButton
            style={{ marginTop: 12 }}
            size='small'
            aria-label="close"
            color="secondary"
            onClick={() => setVariants([...variants.filter(v => v.value !== variant.value)])}>
            <CloseIcon />
          </IconButton> */}
        </Stack>
      })}

      {isRequesting && <Alert color={"warning"} severity='warning'>Saving can take a long time</Alert>}

      {error !== null && <Alert color={"error"} severity="error">{error}</Alert>}
    </SimpleDialog >
  );
}

type UpdateVariantsExperimentDialogProps = {
  payload: UpdateVariantsExperimentDialogPayload
  hideDialog: () => void
}

