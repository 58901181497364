import React, { useEffect, useState } from 'react';
import { Box, Card } from '@mui/material';
import { useInjection } from '../../di/useInjection';
import { Expenses } from '../../network/CustomerActions';
import { Button, useNotify, useRefresh } from 'react-admin';
import { UpdateExpensesDialog, UpdateExpensesDialogPayload } from './widget/UpdateExpensesDialog';
import { centsToDollars } from '../../helpers';

export const ExpensesCollection: React.FC<Props> = ({
  initialFilters,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const { customerActions } = useInjection();

  const [expenses, setExpenses] = useState<Expenses | null>(null);
  const [
    updateExpensesDialogPayload,
    setUpdateExpensesDialogPayload
  ] = React.useState<UpdateExpensesDialogPayload>(null);

  const fetchExpenses = () => {
    const userId = initialFilters?.user_id.eq;
    if (userId !== undefined && userId !== null) {
      customerActions.getExpanses(parseInt(userId)).then((response) => {
        if (response.success) {
          setExpenses(response.result.expenses);
        }
      });
    }
  }

  useEffect(() => {
    fetchExpenses();
  }, [initialFilters?.user_id.eq]);

  const updateExpenses = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const userId = initialFilters?.user_id.eq;

    if (userId !== undefined && userId !== null) {
      setUpdateExpensesDialogPayload({
        expenses: expenses ?? {},
        userId,
      });
    } else {
      notify("no user id in record");
    }
  }

  return (
    <Box sx={{ position: "relative", width: "300px", marginRight: "15px" }}>

      <UpdateExpensesDialog
        payload={updateExpensesDialogPayload}
        onUpdate={fetchExpenses}
        hideDialog={() => setUpdateExpensesDialogPayload(null)} />

      <Box sx={{
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        marginTop: "30px",
        height: "35px",
      }}>
        <h4 style={{ marginBottom: 0 }}>
          {`Expenses: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <Card sx={{ padding: "10px", marginTop: 0 }}>
        <div>Loans and cards: {centsToDollars(expenses?.loansAndCreditCards ?? 0)}</div>
        <div>mortgage: {centsToDollars(expenses?.mortgage ?? 0)}</div>
        <div>other: {centsToDollars(expenses?.other ?? 0)}</div>
        <div>rent: {centsToDollars(expenses?.rent ?? 0)}</div>

      </Card>
      <Button label="update" onClick={updateExpenses} />
    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}