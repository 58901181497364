import {
  Alert,
  Card,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { BTLimits } from "../../../network/BTActions";
import { LimitsCard } from "../../client-collection/widget/LimitsCard";
import { useInjection } from "../../../di/useInjection";
import ForwardIcon from '@mui/icons-material/Forward';
import currency from "currency.js";
import { UnderwritingDetailsUserResult } from "../../../network/UnderwritingActions";

export const CalculateLimits: FC<Props> = ({
  onSetBaseLimit,
  userData,
}) => {
  const { btActions } = useInjection();

  const [calcLimitError, setCalcLimitError] = useState<string | null>(null);
  const [baseLimit, setBaseLimit] = useState<string>("");
  const [approvedLimit, setApprovedLimit] = useState<string>("");
  const [calcLimits, setCalcLimits] = useState<BTLimits | null>(null);

  useEffect(() => {
    const limit = currency(userData?.bt_limit ?? 0, { fromCents: true }).value.toString();
    setApprovedLimit(limit);
    setBaseLimit("");
    setCalcLimits(null);
    setCalcLimitError(null);

    if (limit !== null && limit !== "") {
      calculateLimit(limit, "approvedLimit");
    }
  }, [userData]);

  const onBaseLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setBaseLimit(value);
  }

  const onApprovedLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setApprovedLimit(value);
  }

  const calculateLimit = async (limit: string, limitType: "baseLimit" | "approvedLimit") => {
    if (limit.length === 0) {
      return;
    }

    const payload = limitType === "baseLimit" ? {
      baseLimit: currency(limit).intValue,
    } : {
      approvedLimit: currency(limit).intValue,
    };

    const limitsResponse = await btActions.calcUserLimitsDetails(payload);

    const { error, result } = limitsResponse;

    if (error !== null && error !== undefined) {
      setCalcLimitError(limitsResponse.error.message);
      return;
    }

    if (result !== null && result !== undefined) {
      setCalcLimits(result as BTLimits);
      const baseLimit = currency((result as BTLimits).baseLimit, { fromCents: true }).value.toString()
      const approvedLimit = currency((result as BTLimits).approvedLimit, { fromCents: true }).value.toString()
      onSetBaseLimit(baseLimit);
      if (limitType === "baseLimit") {
        setApprovedLimit(approvedLimit);
      } else {
        setBaseLimit(baseLimit);
      }
    }
  }

  return (<>
    <Stack direction="row" spacing={1}>
      <TextField
        fullWidth
        type="number"
        label="Base Limit (dollars)"
        value={baseLimit}
        onChange={onBaseLimitChange}
        onKeyPress={(e: any) => { if (e.key === "Enter") calculateLimit(baseLimit, "baseLimit"); }}
        variant="outlined"
      />
      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => calculateLimit(baseLimit, "baseLimit")}>
        <ForwardIcon fontSize="inherit" />
      </IconButton>
    </Stack>

    <Stack direction="row" spacing={1}>
      <TextField
        fullWidth
        type="number"
        label="Approved Limit (dollars)"
        value={approvedLimit}
        onChange={onApprovedLimitChange}
        onKeyPress={(e: any) => { if (e.key === "Enter") calculateLimit(approvedLimit, "approvedLimit"); }}
        variant="outlined"
      />

      <IconButton
        aria-label="delete"
        size="small"
        onClick={() => calculateLimit(approvedLimit, "approvedLimit")}>
        <ForwardIcon fontSize="inherit" />
      </IconButton>
    </Stack>

    <Typography variant="caption" gutterBottom sx={{ color: 'text.secondary', mb: 2, }}>
      *change value and press Enter or arrow button
    </Typography>

    <Card sx={{ padding: "10px", marginTop: "5px" }}>
      {/* <h4>Limits</h4> */}

      {calcLimitError !== null && <Alert severity="info">{calcLimitError}</Alert>}

      {calcLimitError === null
        && calcLimits !== null
        && <LimitsCard currentLimits={calcLimits} />
      }
    </Card>
  </>);
}

type Props = {
  onSetBaseLimit: (baseLimit: string) => void
  userData: UnderwritingDetailsUserResult | null
}
