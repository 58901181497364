import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Button as Btn,
  IconButton,
  Stack,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { useInjection } from '../../../di/useInjection';
import { PostError } from '../../../network/AdminRequest';
import { Button, useNotify, useRefresh } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import { ITargetingRules, IVariant } from '../../../network/ABTestActions';

export type AddExperimentDialogPayload = true | undefined;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const triggers = [
  "OnUserCreation", "OnCBActivation", "OnRewardsActivation", "OnSpotActivation",
];

export const AddExperimentDialog: FC<AddExperimentDialogProps> = ({
  payload,
  hideDialog,
}) => {
  const { abTestActions } = useInjection();
  const notify = useNotify();
  const refresh = useRefresh();

  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const [experimentName, setExperimentName] = useState<string>("");
  const [targetingRules, setTargetingRules] = useState<{
    "tag": string
    "contents": string
  }[]>([]);
  const [triggerMoments, setTriggerMoments] = useState<string[]>([]);
  const [variants, setVariants] = useState<IVariant[]>([]);

  useEffect(() => {
    console.log("use effect");
    setError(null);
    setIsRequesting(false);
    setExperimentName("");
    setTriggerMoments([]);
    setTargetingRules([]);
    setVariants([
      {
        "value": "Enabled",
        "probability": 50,
        "isDefaultValue": true
      },
      {
        "value": "Disabled",
        "probability": 50,
        "isDefaultValue": false
      },
    ]);
  }, [payload]);

  const [error, setError] = useState<PostError>(null);

  const handleSendClick = async () => {
    if (isRequesting === undefined) return;

    setIsRequesting(true);
    setError(null);

    const sumProbabilities = variants.map((v) => v.probability).reduce((a, sum) => a + sum, 0);

    if (sumProbabilities !== 100) {
      setIsRequesting(false);
      setError("Sum of probability should equal 100");
      return;
    }

    const _targetingRules: ITargetingRules[] = []
    for (const tr of targetingRules) {
      try {
        const contents = JSON.parse(tr.contents);
        _targetingRules.push({
          ...tr,
          contents,
        })
      } catch (e) {
        setIsRequesting(false);

        if (e instanceof Error) {
          setError("Contents error parse: " + e.message);
        } else {
          setError("Contents error parse");
        }

        return;
      }
    }

    const error = await abTestActions.addAbTest({
      experimentName,
      triggerMoments,
      targetingRules: _targetingRules,
      variants,
    });

    setIsRequesting(false);
    if (error === null) {
      notify("Done");
      hideDialog();
      refresh();
    } else {
      setError(error);
    }
  }

  const handleExperimentNameUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setExperimentName(value);
  }

  const handleUpdateVariantValue = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    const variantsSnapshot = [...variants];
    const value = e.target.value;
    const variant = variantsSnapshot[index];
    variantsSnapshot[index] = { ...variant, value }
    setVariants(variantsSnapshot);
  }

  const handleUpdateTRTag = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    const trsSnapshot = [...targetingRules];
    const tag = e.target.value;
    const tr = trsSnapshot[index];
    trsSnapshot[index] = { ...tr, tag }
    setTargetingRules(trsSnapshot);
  }

  const handleUpdateTRContents = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    e.preventDefault();
    const trsSnapshot = [...targetingRules];
    const contents = e.target.value;
    const tr = trsSnapshot[index];
    trsSnapshot[index] = { ...tr, contents }
    setTargetingRules(trsSnapshot);
  }

  const handleTriggerMoments = (event: SelectChangeEvent<typeof triggerMoments>) => {
    const {
      target: { value },
    } = event;
    setTriggerMoments(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleUpdateVariantProbability = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    try {
      const variantsSnapshot = [...variants];
      const probability = parseInt(e.target.value);
      const variant = variantsSnapshot[index];
      variantsSnapshot[index] = { ...variant, probability }
      setVariants(variantsSnapshot);
    } catch (_) { }
  }

  const handleUpdateDefaultFlag = (e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean, index: number) => {
    e.stopPropagation();
    const variant = variants[index];
    const _variants = isSelect ? variants.map((v) => {
      v.isDefaultValue = false;
      return v;
    }) : variants;

    _variants[index] = { ...variant, isDefaultValue: isSelect }

    setVariants([..._variants]);
  }

  return (
    <SimpleDialog
      isShow={payload !== undefined}
      onClose={hideDialog}
      title="Add ab test"
      onSend={handleSendClick}
      isSendDisabled={isRequesting}
      cancelTitle='CLOSE'
      actionTitle={isRequesting ? "Saving..." : "Add"}>

      <TextField
        fullWidth
        id="experimentName"
        label="Experiment Name"
        value={experimentName}
        InputLabelProps={{ shrink: true }}
        onChange={handleExperimentNameUpdate}
      />

      <FormControl sx={{ marginBottom: 3, width: "100%" }}>
        <InputLabel id="demo-multiple-name-label">Trigger moments</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={triggerMoments}
          onChange={handleTriggerMoments}
          MenuProps={MenuProps}
        >
          {triggers.map((trigger) => (
            <MenuItem
              key={trigger}
              value={trigger}
            >
              {trigger}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box style={{ "display": "flex" }}>
        <h6 style={{ marginTop: 8, marginRight: 10 }}>Variants</h6>
        <Button
          size='small'
          label="add"
          onClick={() => setVariants([...variants, { value: '', isDefaultValue: false, probability: 0 }])} />
      </Box>

      {variants.map((variant, index) => {
        return <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            id={`variant_value_${index}`}
            label="value"
            value={variant.value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateVariantValue(e, index)}
          />

          <TextField
            fullWidth
            id={`variant_probability_${index}`}
            label="probability"
            value={variant.probability}
            type="number"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateVariantProbability(e, index)}
            style={{ marginTop: 8 }}
          />

          <FormControlLabel
            style={{ marginTop: 12 }}
            id={`variant_default_${index}`}
            control={
              <Checkbox
                checked={variant.isDefaultValue}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>, isSelected: boolean) => handleUpdateDefaultFlag(e, isSelected, index)
                }
              />}
            label="" />

          <IconButton
            style={{ marginTop: 12 }}
            size='small'
            aria-label="close"
            color="secondary"
            // переделать на удаление по индексу
            onClick={() => setVariants([...variants.filter(v => v.value !== variant.value)])}>
            <CloseIcon />
          </IconButton>
        </Stack>
      })}

      <Box style={{ "display": "flex" }}>
        <h6 style={{ marginTop: 8, marginRight: 10 }}>Targeting rules</h6>
        <Button
          size='small'
          label="add"
          onClick={() => setTargetingRules([...targetingRules, { tag: '', contents: '{"states": []}' }])} />
      </Box>

      {targetingRules.map((targetingRule, index) => {
        return <Box>

          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              id={`tr_tag_${index}`}
              label="tag"
              value={targetingRule.tag}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateTRTag(e, index)}
            />

            <IconButton
              style={{ marginTop: 12 }}
              size='small'
              aria-label="close"
              color="secondary"
              // переделать на удаление по индексу
              onClick={() => setTargetingRules([...targetingRules.filter(tr => tr.tag !== targetingRule.tag)])}>
              <CloseIcon />
            </IconButton>

          </Stack>

          <TextField
            fullWidth
            id={`tr_contents_${index}`}
            label="contents (correct JSON)"
            multiline
            minRows={3}
            value={targetingRule.contents}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleUpdateTRContents(e, index)}
          />

        </Box>
      })}

      {isRequesting && <Alert color={"warning"} severity='warning'>Saving can take a long time</Alert>}

      {error !== null && <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog >
  );
}

type AddExperimentDialogProps = {
  payload: AddExperimentDialogPayload
  hideDialog: () => void
}

