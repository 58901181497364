import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UpdateIcon from '@mui/icons-material/Update';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import { UpdatePrimaryStatusDialogPayload } from './UpdatePrimaryStatusDialog';
import { Box } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export const RowActions: React.FC<Props> = ({
  record,
  showUpdateDialog,
  handleUpdateManualReviewStatus,
  showAddACHAccountDialog,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const notify = useNotify();
  const refresh = useRefresh();
  const { underwritingActions } = useInjection();

  const handleOpenButtonPopup = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(null);
  };

  const handleRevokeByItem = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (!window.confirm(`Revoke by item`)) {
      setAnchorEl(null);
      return;
    }

    const { user_id: userId, item_id: plaidItemId } = record;

    const error = await underwritingActions.revokePlaidAccountByItem({ userId, plaidItemId });

    if (error !== null) {
      notify(error);
    } else {
      notify("revoked");
      refresh();
    }

    setAnchorEl(null);
  }

  const handleCloseByItem = async (e: React.MouseEvent) => {
    e.preventDefault();

    const { user_id: userId, item_id: plaidItemId } = record;

    const error = await underwritingActions.closePlaidAccountByItem({ userId, plaidItemId })

    if (error !== null) {
      notify(error);
    } else {
      notify("closed");
      refresh();
    }

    setAnchorEl(null);
  }

  const handleCloseByIds = async (e: React.MouseEvent) => {
    e.preventDefault();

    const { user_id: userId, account_id: plaidId } = record;

    const error = await underwritingActions.closePlaidAccountByIds({ userId, plaidIds: [plaidId] })

    if (error !== null) {
      notify(error);
    } else {
      notify("closed");
      refresh();
    }

    setAnchorEl(null);
  }

  const handleUpdatePrimaryStatus = async (e: React.MouseEvent) => {
    e.preventDefault();

    const {
      user_id: userId,
      account_id: plaidRawAccountId,
    } = record;

    showUpdateDialog({
      userId,
      plaidRawAccountId,
    })

    setAnchorEl(null);
  }

  const handleAddAccountAndRouting = async (e: React.MouseEvent) => {
    e.preventDefault();

    const {
      account_id: plaidRawAccountId,
      user_id,
    } = record;

    showAddACHAccountDialog(plaidRawAccountId, parseInt(user_id));

    setAnchorEl(null);
  }

  const handleApprove = async (e: React.MouseEvent) => {
    e.preventDefault();

    const {
      account_id: plaidRawAccountId,
      user_id,
    } = record;

    const error = await underwritingActions.depositoryAccountsApprove(user_id);

    if (error !== null) {
      notify(error);
    } else {
      notify("approve");
      refresh();
    }


    setAnchorEl(null);
  }

  const handleReject = async (e: React.MouseEvent) => {
    e.preventDefault();

    const {
      account_id: plaidRawAccountId,
      user_id,
    } = record;

    const error = await underwritingActions.depositoryAccountsReject(user_id);

    if (error !== null) {
      notify(error);
    } else {
      notify("rejected");
      refresh();
    }

    if (error == null) {

    }

    setAnchorEl(null);
  }

  return (
    <div>
      <Button
        size='small'
        color='inherit'
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleOpenButtonPopup}
        endIcon={<KeyboardArrowDownIcon />}>
        Actions
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>

        <MenuItem onClick={handleRevokeByItem} disableRipple>
          <DeleteIcon />
          revoke by item
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />

        <MenuItem onClick={(e) => {
          e.preventDefault();
          setAnchorEl(null);
          handleUpdateManualReviewStatus(record);
        }} disableRipple>
          <UpdateIcon />
          update manual review status
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />

        <MenuItem onClick={handleCloseByItem} disableRipple>
          <CloseIcon />
          close by item
        </MenuItem>

        <MenuItem onClick={handleCloseByIds} disableRipple>
          <CloseIcon />
          close by ids
        </MenuItem>

        {
          ["savings", "checking"].includes(record.subtype) &&
          <Box>
            <Divider sx={{ my: 0.5 }} />

            <MenuItem onClick={handleUpdatePrimaryStatus} disableRipple>
              <UpdateIcon />
              update primary status
            </MenuItem>
          </Box>
        }

        <Divider sx={{ my: 0.5 }} />

        <MenuItem onClick={handleAddAccountAndRouting} disableRipple>
          <UpdateIcon />
          update account and routing numbers
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />

        <MenuItem onClick={handleApprove} disableRipple>
          <UpdateIcon />
          Approve
        </MenuItem>

        <MenuItem onClick={handleReject} disableRipple>
          <UpdateIcon />
          Reject
        </MenuItem>

      </StyledMenu>
    </div>
  );
}

type Props = {
  record: { [index: string]: any }
  showUpdateDialog: (payload: UpdatePrimaryStatusDialogPayload) => void
  handleUpdateManualReviewStatus: (record: { [index: string]: any }) => void
  showAddACHAccountDialog: (plaidRawAccountId: string, userId: number) => void
}