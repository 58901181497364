import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Button as Btn,
  IconButton,
  Stack,
} from '@mui/material';
import { Button, useNotify, useRefresh } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import { useInjection } from '../../../di/useInjection';
import { Expenses } from '../../../network/CustomerActions';
import { PostError } from '../../../network/AdminRequest';
import { SimpleDialog } from '../../../common/SimpleDialog';
import currency from 'currency.js';

export type UpdateExpensesDialogPayload = {
  expenses: Expenses
  userId: string
} | null;

export const UpdateExpensesDialog: FC<UpdateExpensesDialogProps> = ({
  payload,
  hideDialog,
  onUpdate,
}) => {
  const { customerActions } = useInjection();
  const notify = useNotify();
  const refresh = useRefresh();

  const [loansAndCreditCards, setLoansAndCreditCards] = useState<string | undefined>();
  const [mortgage, setMortgage] = useState<string | undefined>();
  const [other, setOther] = useState<string | undefined>();
  const [rent, setRent] = useState<string | undefined>();

  const initValue = (value: number | undefined) => {
    return ((value ?? 0) / 100).toString();
  }

  useEffect(() => {
    console.log("use effect");
    setError(null);
    setLoansAndCreditCards(initValue(payload?.expenses?.loansAndCreditCards));
    setMortgage(initValue(payload?.expenses?.mortgage));
    setOther(initValue(payload?.expenses?.other));
    setRent(initValue(payload?.expenses?.rent));
  }, [payload]);

  const [error, setError] = useState<PostError>(null);

  const handleSendClick = async () => {
    setError(null);

    const rentInt = parseInt(rent ?? "") * 100;
    const otherInt = parseInt(other ?? "") * 100;
    const loansAndCardsInt = parseInt(loansAndCreditCards ?? "") * 100;
    const mortgageInt = parseInt(mortgage ?? "") * 100;

    const userId = payload?.userId;

    if (userId === undefined || userId === null) {
      notify("No user id");
      return;
    }

    const error = await customerActions.updateExpanses({
      userId: parseInt(userId),
      expenses: {
        rent: rentInt,
        other: otherInt,
        loansAndCreditCards: loansAndCardsInt,
        mortgage: mortgageInt,
      }
    });

    if (error === null) {
      notify("Done");
      hideDialog();
      onUpdate()
      refresh();
    } else {
      setError(error);
    }
  }

  const handleUpdateLoanAndCreditCards = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLoansAndCreditCards(value);
  }

  const handleUpdateMortgage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMortgage(value);
  }

  const handleUpdateOther = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOther(value);
  }

  const handleUpdateRent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRent(value);
  }

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Update expenses"
      onSend={handleSendClick}
      cancelTitle='CLOSE'
      actionTitle="Update">

      <TextField
        fullWidth
        id={"expenses_loan"}
        label="loans and credit cards (dollar)"
        value={loansAndCreditCards}
        type="number"
        onChange={handleUpdateLoanAndCreditCards}
        style={{ marginTop: 8 }}
      />

      <TextField
        fullWidth
        id={"expenses_mortgage"}
        label="mortgage (dollar)"
        value={mortgage}
        type="number"
        onChange={handleUpdateMortgage}
        style={{ marginTop: 8 }}
      />

      <TextField
        fullWidth
        id={"expenses_other"}
        label="other (dollar)"
        value={other}
        type="number"
        onChange={handleUpdateOther}
        style={{ marginTop: 8 }}
      />

      <TextField
        fullWidth
        id={"expenses_rent"}
        label="rent (dollar)"
        value={rent}
        type="number"
        onChange={handleUpdateRent}
        style={{ marginTop: 8 }}
      />

      {error !== null && <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog >
  );
}

type UpdateExpensesDialogProps = {
  payload: UpdateExpensesDialogPayload
  hideDialog: () => void
  onUpdate: () => void
}

