import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DateInput,
  FilterButton,
  NumberInput,
  SelectInput,
  TextInput,
  useNotify,
  useRefresh,
} from "react-admin"
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { RowClickEvent } from '../../common/Table';
import { StartUnderwritingDialog, StartUnderwritingDialogPayload } from './widget/StartUnderwritingDialog';
import { UpdateUnderwritingDialog, UpdateUnderwritingDialogPayload } from './widget/UpdateUnderwritingDialog';
import { useInjection } from '../../di/useInjection';
import { Box } from '@mui/material';
import { DollarInput } from '../../common/DollarInput';
import fraudIdConfig from '../../configs/fraud_id.json';
import { ForceVefiffDialog, ForceVeriffDialogPayload } from './widget/ForceVeriffDialog';
import { RowActions } from './widget/RowActions';

export const resource = "admin/generic-views/underwriting_details";

const tableColumns = [
  { field: "id", width: "120px", title: "id", show: true },
  { field: "user_id", width: "120px", title: "user id", show: true },
  { field: "created_at", width: "120px", title: "created at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "result", width: "120px", title: "result", show: true },
  { field: "product", width: "120px", title: "product", show: true },
  { field: "is_monitoring", width: "120px", title: "is monitoring", show: true },
  { field: "equifax_fico", width: "120px", title: "eqx fico", show: true },
  { field: "experian_fico", width: "90px", title: "expn fico", show: true },
  { field: "array_fico", width: "90px", title: "expn fico", show: true },
  { field: "equifax_vantage", width: "80px", title: "eqx vantage", show: true },
  { field: "equifax_fraud_id", width: "200px", title: "eqx fraud", show: true, },
  { field: "equifax_ssn_match", width: "80px", title: "eqx ssn match", show: true },
  { field: "bt_limit", width: "120px", title: "bt limit", show: true, isCents: true },
  { field: "pb_limit", width: "120px", title: "bt limit", show: true, isCents: true },
  { field: "has_freeze_code", width: "120px", title: "has freeze code", show: true },
  { field: "ud_hit_code", width: "120px", title: "ud hit code", show: true },

  { field: "monthly_payment", width: "120px", title: "mo pay", show: true, isCents: true },
  { field: "total_inquiries", width: "140px", title: "total inquiries", show: true, },
  { field: "inquiries_during_last6_months", width: "150px", title: "inq last6 mo", show: true, },
  { field: "now_delinquent_derog", width: "170px", title: "now delinqt derog", show: true, },
  { field: "was_delinquent_derog", width: "170px", title: "was delinqt derog", show: true, },
  { field: "delinquencies30days", width: "160px", title: "delinqt 30 d", show: true, },
  { field: "delinquencies60days", width: "160px", title: "delinqt 60 d", show: true, },
  { field: "delinquencies90to180days", width: "160px", title: "delinqt 90-180 d", show: true, },
  { field: "delinquencies90to180days", width: "160px", title: "delinqt 90-180 d", show: true, },
  { field: "bt_apr", width: "120px", title: "bt apr", show: true },

  { field: "comment", width: "120px", title: "comment", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" alwaysOn />,

  <DateInput label="created at gt" source="created_at.gt" />,
  <DateInput label="created at lt" source="created_at.lt" />,

  <SelectInput source="result" choices={[
    { id: 'Pending', name: 'Pending' },
    { id: 'RequestManual', name: 'RequestManual' },
    { id: 'Reject', name: 'Reject' },
    { id: 'Approved', name: 'Approved' },
  ]} />,

  <SelectInput source="product" choices={[
    { id: 'BalanceTransferLine', name: 'BalanceTransferLine' },
    { id: 'BillPayLine Offer', name: 'BillPayLine Offer' },
    { id: 'BillPayLine', name: 'BillPayLine' },
  ]} />,

  <SelectInput source="is_monitoring" choices={[
    { id: 'true', name: 'Enabled' },
    { id: 'false', name: 'Disabled' },
  ]} />,

  <NumberInput label="equifax fico gt" source="equifax_fico.gt" />,
  <NumberInput label="equifax fico lt" source="equifax_fico.lt" />,

  <NumberInput label="experian fico gt" source="experian_fico.gt" />,
  <NumberInput label="experian fico lt" source="experian_fico.lt" />,

  <DollarInput label="bt_limit gt (dollar)" source="bt_limit.gt" />,
  <DollarInput label="bt_limit lt (dollar)" source="bt_limit.lt" />,

  <DollarInput label="pb_limit gt (dollar)" source="pb_limit.gt" />,
  <DollarInput label="pb_limit lt (dollar)" source="pb_limit.lt" />,

  <NumberInput label="equifax vantage id" source="equifax_vantage.eq" />,

  <SelectInput source="equifax_ssn_match" choices={[
    { id: "SSN'VerifiedByReport", name: "SSN'VerifiedByReport" },
    { id: "SSN'Match", name: "SSN'Match" },
    { id: "SSN'NoMatch", name: "SSN'NoMatch" },
    { id: "SSN'NoHit", name: "SSN'NoHit" },
    { id: "SSN'Error", name: "SSN'Error" },
    { id: "SSN'UnableToExtractFromResponse", name: "SSN'UnableToExtractFromResponse" },
    { id: "SSN'CheckDisabled", name: "SSN'CheckDisabled" },
  ]} />,

  <TextInput label="equifax fraud id" source="equifax_fraud_id.eq" />,
];

export const UnderwritingCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
    onRowClick,
    disableUrlFilterListener
  } = props;
  const [startUnderwritingDialogPayload, setStartUnderwritingDialogPayload] = useState<StartUnderwritingDialogPayload>(null);
  const [updateUnderwritingDialogPayload, setUpdateUnderwritingDialogPayload] = useState<UpdateUnderwritingDialogPayload>(null);
  const [forceVeriffDialogPayload, setForceVeriffDialogPayload] = useState<ForceVeriffDialogPayload>(null);

  const notify = useNotify();
  const refresh = useRefresh();
  const { underwritingActions } = useInjection();

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    if (onRowClick === undefined) return;

    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { id } = dataItem;

    if (id === undefined) {
      throw Error('no user_id in row click event');
    }

    onRowClick(id);
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  const handleOpenStartUnderwriting = (e: React.MouseEvent) => {
    e.preventDefault();

    const userId = initialFilters?.user_id?.eq;

    if (userId === undefined) {
      notify("userId is undefined");
      return;
    }

    setStartUnderwritingDialogPayload({ userId });
  }

  const handleOpenUpdateUnderwritingWithId = (e: React.MouseEvent, decisionId: string) => {
    e.preventDefault();

    console.log(decisionId);
    const _decisionIdList = decisionId.split("-");
    const _decisionId = parseInt(_decisionIdList[_decisionIdList.length - 1]);

    const userId = initialFilters?.user_id?.eq;

    if (userId === undefined) {
      notify("userId is undefined");
      return;
    }

    setUpdateUnderwritingDialogPayload({
      userId,
      decisionId: _decisionId,
    });
  }

  const handleOpenUpdateUnderwriting = (e: React.MouseEvent) => {
    e.preventDefault();

    const userId = initialFilters?.user_id?.eq;

    if (userId === undefined) {
      notify("userId is undefined");
      return;
    }

    setUpdateUnderwritingDialogPayload({
      userId,
      decisionId: null,
    });
  }

  const handleOpenForceVeriff = (e: React.MouseEvent) => {
    e.preventDefault();

    const userId = initialFilters?.user_id?.eq;

    if (userId === undefined) {
      notify("userId is undefined");
      return;
    }

    setForceVeriffDialogPayload({
      userId,
    });
  }

  const handleSendStartUnderwriting = async (
    payload: any
  ) => {
    notify("The underwriting started.");

    const error = payload['lineType'] === undefined
      ? await underwritingActions.startUnderwritingWithMonitoring(payload)
      : await underwritingActions.startUnderwritingWithOutMonitoring(payload);

    if (error !== null) {
      notify(error);
      return;
    }

    refresh();
  }

  const handleLoadEquifax = async () => {
    const userId = initialFilters?.user_id.eq.toString();

    try {
      const _userId = parseInt(userId);

      const error = await underwritingActions.loadEquifax(_userId)

      if (error !== null) {
        notify(error);
        return;
      }

      notify("DONE");
      refresh();
    } catch (e) {
      notify("Something went wrong with userId ${userId}");
    }
  }

  const handleLoadExperian = async () => {
    const userId = initialFilters?.user_id.eq.toString();

    try {
      const _userId = parseInt(userId);

      const error = await underwritingActions.loadExperian(_userId)

      if (error !== null) {
        notify(error);
        return;
      }

      notify("DONE");
      refresh();
    } catch (e) {
      notify("Something went wrong with userId ${userId}");
    }
  }


  const rowActionBuilder = (record: { [index: string]: any }) =>
    <RowActions record={record} />
  // <Button
  //   label='Update decision'
  //   onClick={(e) => handleOpenUpdateUnderwritingWithId(e, record['id'])}
  // />

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Underwriting: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <StartUnderwritingDialog
        payload={startUnderwritingDialogPayload}
        hideDialog={() => setStartUnderwritingDialogPayload(null)}
        onSend={handleSendStartUnderwriting}
      />

      <UpdateUnderwritingDialog
        userId={initialFilters?.user_id.eq}
        payload={updateUnderwritingDialogPayload}
        hideDialog={() => setUpdateUnderwritingDialogPayload(null)}
      />

      <ForceVefiffDialog
        payload={forceVeriffDialogPayload}
        hideDialog={() => setForceVeriffDialogPayload(null)}
      />

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        filters={filters}
        onRowClick={handleRowClick}
        columnModel={columnModel}
        disableUrlFilterListener={disableUrlFilterListener}
        rowActionBuilder={rowActionBuilder}
        tableActions={
          <>
            <Button label="load experian" onClick={handleLoadExperian} />
            <Button label="load equifax" onClick={handleLoadEquifax} />
            <Button label="update underwriting" onClick={handleOpenUpdateUnderwriting} />
            <Button label="start underwriting" onClick={handleOpenStartUnderwriting} />
            <Button label="force veriff" onClick={handleOpenForceVeriff} />
            <FilterButton {...props} />
          </>
        }
        customValueFormat={{
          "equifax_fraud_id": (fraudId?: string) => {
            if (fraudId === undefined || fraudId === null) {
              return '';
            }

            const fraudDescription = (fraudIdConfig as { [index: string]: string })[fraudId];
            return fraudDescription ?? `Unknown description with id: ${fraudId}`;
          },
        }
        }
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
  disableUrlFilterListener: boolean
}