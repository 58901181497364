import React from 'react';

import { AdminRequest } from '../network/AdminRequest';

import { DebtActions } from '../network/DebtActions';
import { UnderwritingActions } from '../network/UnderwritingActions';
import { ClientActions } from '../network/ClientActions';
import { BTActions } from '../network/BTActions';
import { RepaymentsActions } from '../network/RepaymentsActions';
import { AccessControlActions } from '../network/AccessControlActions';
import { ManualReviewActions } from '../network/ManualReviewActions';
import { HistoricalPlaidTransactionActions } from '../network/HistoricalPlaidTransactionActions';
import { DepositoryActions } from '../network/DepositoryActions';
import { MembershipActions } from '../network/MembershipActions';
import { PlaidIdentityActions } from '../network/PlaidIdentityActions';
import { CollectionActions } from '../network/CollectionActions';
import { ABTestActions } from '../network/ABTestActions';
import { CustomerActions } from '../network/CustomerActions';


const actionRequestBuilder = () => {
  const token = localStorage.getItem('token') ?? "";

  if (token === null) {
    throw Error("Build actionRequest failed: token not found.");
  }

  const adminRequest = new AdminRequest(token);

  const debtActions = new DebtActions(adminRequest);
  const underwritingActions = new UnderwritingActions(adminRequest);
  const clientActions = new ClientActions(adminRequest);
  const btActions = new BTActions(adminRequest);
  const repaymentsActions = new RepaymentsActions(adminRequest);
  const accessControlActions = new AccessControlActions(adminRequest);
  const manualReviewActions = new ManualReviewActions(adminRequest);
  const historicalPlaidTransactionActions = new HistoricalPlaidTransactionActions(adminRequest);
  const depositoryActions = new DepositoryActions(adminRequest);
  const membershipActions = new MembershipActions(adminRequest);
  const plaidIdentityActions = new PlaidIdentityActions(adminRequest);
  const collectionActions = new CollectionActions(adminRequest);
  const abTestActions = new ABTestActions(adminRequest);
  const customerActions = new CustomerActions(adminRequest);

  return {
    adminRequest,
    debtActions,
    underwritingActions,
    clientActions,
    btActions,
    repaymentsActions,
    accessControlActions,
    manualReviewActions,
    historicalPlaidTransactionActions,
    depositoryActions,
    membershipActions,
    plaidIdentityActions,
    collectionActions,
    abTestActions,
    customerActions,
  }
}

export const Container = {
  ...actionRequestBuilder(),
};

export type DIContainerType = typeof Container;
export const DIContext = React.createContext<Partial<DIContainerType>>(Container);